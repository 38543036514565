import "swiper/css";
import "swiper/css/autoplay";
import "swiper/css/navigation";
import Layout from "../../components/Layout";
import { useParams } from "react-router-dom";
import { useAppData } from "../../hooks/useAppData";
import { slugify } from "../../utils/slugify";
import Gallery from "../../components/Gallery";
import { useState } from "react";

const ProjectDetails = () => {
  const [openModal, setOpenModal] = useState(false);
  const [current, setCurrent] = useState(0);
  const appData = useAppData();
  const { slug } = useParams();

  const project = appData?.projects.find(
    (project) => slugify(project.title) === slug,
  );

  const openGallery = (index: number) => {
    setCurrent(index);
    setOpenModal(true);
  };

  if (!project) {
    return (
      <Layout
        label="Project Details"
        backgroundImage="/images/project-details.jpg"
      >
        <h3>Project Not Found</h3>
      </Layout>
    );
  }

  return (
    <Layout
      label="Project Details"
      backgroundImage="/images/project-details.jpg"
    >
      <div className="max-w-6xl mx-auto px-5">
        <img
          src={project.thumbnail_image}
          className="h-96 w-full object-cover"
        />
        <div className="grid grid-cols-12 mt-10 gap-5">
          <div className="text-white p-7 col-span-12 md:col-span-5 lg:col-span-4 bg-gray-800 min-h-96">
            <h3 className="text-xl font-semibold tracking-wide">
              Project Information
            </h3>
            <ul className="mt-2 divide-y divide-gray-500">
              <InfoItem label="Client:" value={project.info.client} />
              <InfoItem label="Location:" value={project.info.location} />
              <InfoItem label="Area(sf):" value={project.info.area} />
              <InfoItem label="Year:" value={project.info.year} />
              <InfoItem label="Budget:" value={`$${project.info.budget}`} />
              <InfoItem label="Arhitect:" value={project.info.architect} />
            </ul>
          </div>
          <div className="col-span-12 md:col-span-7 lg:col-span-8">
            <h2 className="text-4xl font-semibold mb-2">{project.title}</h2>
            <p>{project.description}</p>
          </div>
        </div>
        <div className="mt-10 flex flex-wrap gap-5">
          {project.project_images.map((image, idx) => (
            <img
              onClick={() => openGallery(idx)}
              src={image}
              key={idx}
              className="w-60 h-60 cursor-pointer border shadow-md"
            />
          ))}
        </div>
        {openModal ? (
          <Gallery
            current={current}
            images={project.project_images}
            setOpenModal={setOpenModal}
          />
        ) : null}
      </div>
    </Layout>
  );
};

function InfoItem({ label, value }: { label: string; value: string }) {
  return (
    <li className="py-3">
      <span className="font-semibold w-24 inline-block">{label}</span>
      <span>{value}</span>
    </li>
  );
}

export default ProjectDetails;
