import React, { useState } from "react";
import { useAppStore } from "../store/appStore";

export const Partner = ({ partner }: { partner: { name: string; link: string; image: string } }) => {
    const [isHovered, setIsHovered] = useState(false);
  
    return (
        <div className="mx-5 relative" onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
            <a href={partner.link} target="_blank" rel="noopener noreferrer">
                <img src={partner.image} alt={partner.name} className="w-24 h-auto mx-auto rounded-lg" />
            </a>
            {isHovered && (
                <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50 text-white text-center transition duration-300 rounded-lg">
                    <p className="text-sm">{partner.name}</p>
                </div>
            )}
        </div>
    );
};