import { useAppStore } from "../store/appStore";
import { Virtual } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/virtual';

export const TestimonialsSection = () => {
  const appData = useAppStore((store) => store.appData);

  const slides = Array.from({ length: 1000 }).map(
    (el, index) => `Slide ${index + 1}`
  );
  
  return (
    <section style={{ backgroundColor: '#f3f4f6' }}>
      {/* <h2 className="section-title">Testimonials</h2>
        <p className="section-subtitle mb-8">What our clients say about us</p> */}
          
      <section
        className="py-20 relative"
        style={{ 
          backgroundImage: `url(${appData?.about_us_video})`, 
          backgroundSize: "cover", 
          backgroundPosition: "center" 
        }}
      >
        <div className="absolute inset-0 bg-black opacity-30"></div>

        <div className="container mx-auto">
          <div className="mt-10 max-w-3xl mx-auto">
            <Swiper autoplay modules={[Virtual]} spaceBetween={50} slidesPerView={1} virtual
            >
              <div className="swiper-wrapper"> {/* Wrapper for testimonial items */}
                {appData?.testimonials?.map((testimonial: any, index) => (
                  <SwiperSlide key={testimonial} virtualIndex={index} className="swiper-slide bg-white border border-gray-200 p-6 rounded-lg flex-shrink-0 w-150 black"> {/* Testimonial item */}
                    <div className="flex items-center mb-4">
                      <img src={testimonial.profile_image} alt={testimonial.name} className="w-20 h-20 rounded-full mr-4" />
                      <div>
                        <p className="text-lg">{testimonial.description}</p>
                        <p className="font-semibold mt-1">{testimonial.name}</p>
                        <p className="text-gray-500">{testimonial.designation}</p>
                      </div>
                    </div>
                  </SwiperSlide>
                ))}
              </div>
            </Swiper>
          </div>
        </div>
        
      </section>
    </section>
  );
};
