import React from "react";
import { IoMdMap } from "react-icons/io";
import { BsTelephone } from "react-icons/bs";
import { AiOutlineMail } from "react-icons/ai";
import { useAppData } from "../hooks/useAppData";
import { formatPhoneNumberIntl } from "react-phone-number-input";

const Contact = () => {
  const appData = useAppData();

  return (
    <div className="pt-16 md:pt-0">
      <div className="relative h-28 md:h-56 bg-black/70 flex justify-center items-center">
        <img
          src="/images/about.jpg"
          alt=""
          className="absolute w-full h-full object-cover mix-blend-overlay object-center"
        />
        <h3 className="text-white text-3xl md:text-[42px] font-semibold">
          Contact Us
        </h3>
      </div>

      <div className="p-5 md:p-16 lg:p-28 gap-5">
        <div className="h-[400px] w-full">
          <iframe
            title="google-map"
            src={`https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d392436.93004030554!2d${appData?.contact_longitude}!3d${appData?.contact_latitude}!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2sbd!4v1558429398879!5m2!1sen!2sbd`}
            allowFullScreen
            className="h-full w-full"
          ></iframe>
        </div>

        <div className="grid grid-cols-1 lg:grid-cols-12 mt-10 gap-5">
          <div className="col-span-12 lg:col-span-4">
            <div className="contact-information">
              <h3 className="font-semibold text-2xl mb-5">
                Contact Information
              </h3>
              <ul className="space-y-4">
                <li className="flex gap-5 items-center">
                  <IoMdMap size={30} />
                  <span className="text">
                    <span>{appData?.company_address}</span>
                  </span>
                </li>
                <li className="flex gap-5 items-center">
                  <BsTelephone size={20} />
                  <span className="text">
                    <a href={`tel:${appData?.contact_phone_1}`}>
                      {formatPhoneNumberIntl(appData?.contact_phone_1 ?? "")}{" "}
                      <br />
                    </a>
                    <a href={`tel:${appData?.contact_phone_2}`}>
                      {formatPhoneNumberIntl(appData?.contact_phone_2 ?? "")}{" "}
                    </a>
                  </span>
                </li>
                <li className="flex gap-5 items-center">
                  <AiOutlineMail size={25} />
                  <span className="text">
                    <a href={`mailto:${appData?.contact_email_1}`}>
                      {appData?.contact_email_1}
                    </a>{" "}
                    <br />
                    <a href={`mailto:${appData?.contact_email_2}`}>
                      {appData?.contact_email_2}
                    </a>
                  </span>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-span-12 lg:col-start-6 lg:col-span-7">
            <div className="contact-form">
              <h3 className="font-semibold text-2xl mb-5">
                Leave Your Message
              </h3>
              <form>
                <div className="flex gap-5">
                  <input
                    name="con_name"
                    type="text"
                    placeholder="Your Name"
                    className="flex-1 border border-gray-400 p-2 rounded"
                  />
                  <input
                    name="con_email"
                    type="email"
                    placeholder="Your Email"
                    className="flex-1 border border-gray-400 p-2 rounded"
                  />
                </div>
                <div className="mt-5">
                  <textarea
                    name="con_message"
                    placeholder="Your Message"
                    defaultValue={""}
                    className="border border-gray-400 p-2 rounded w-full"
                  />
                </div>
                <button className="bg-orange-600 text-white py-4 px-14 text-sm mt-5">
                  Send Message
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
