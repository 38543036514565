import { Link } from "react-router-dom";
import { useAppData } from "../../hooks/useAppData";
import Layout from "../../components/Layout";

const Services = () => {
  const appData = useAppData();

  return (
    <Layout label="Services" backgroundImage="/images/service3.jpg">
      <div className="p-10 flex flex-wrap gap-7 justify-center lg:max-w-7xl mx-auto">
        {appData?.services.map((service, index) => (
          <div
            key={index}
            className="flex-1 basis-[330px] max-w-sm h-[360px] md:h-[420px] shadow-md rounded-md overflow-hidden group cursor-pointer"
          >
            <div className="overflow-hidden">
              <img
                src={service.icon}
                alt=""
                className="h-60 w-full object-cover group-hover:scale-105 rounded-b-md duration-500"
              />
            </div>
            <div className="p-2">
              <h4 className="font-bold text-xl md:text-2xl text-gray-800 line-clamp-1">
                {service.title}
              </h4>
              <p className="line-clamp-3 py-2 h-20">{service.description}</p>
              <Link
                to={`/services/${service.title}`}
                className="block text-wine mt-2 text-center"
              >
                See More...
              </Link>
            </div>
          </div>
        ))}
      </div>
    </Layout>
  );
};

export default Services;
