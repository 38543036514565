import React from "react";

interface LayoutProps {
  backgroundImage: string;
  label: string;
  children: React.ReactNode;
}

const Layout = ({ backgroundImage, label, children }: LayoutProps) => {
  return (
    <div className="py-16 md:pt-0">
      <div className="relative mb-10 h-28 md:h-56 bg-black/70 flex justify-center items-center">
        <img
          src={backgroundImage}
          alt=""
          className="absolute w-full h-full object-cover object-center mix-blend-overlay"
        />
        <h3 className="text-white text-2xl md:text-[42px] font-semibold">
          {label}
        </h3>
      </div>

      {children}
    </div>
  );
};

export default Layout;
