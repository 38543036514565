import { create } from "zustand";
import { persist } from "zustand/middleware";
import { WebsiteData } from "../custom-types";

interface AppState {
  appData: WebsiteData | null;
  setData: (data: WebsiteData) => void;
}

export const useAppStore = create<AppState>()(
  persist(
    (set) => ({
      appData: null,
      setData: (data) => set((state) => ({ appData: data })),
    }),
    {
      name: "app-data",
    }
  )
);
