import { useRef, useEffect } from "react";
import { useAppStore } from "../store/appStore";

export const StatisticCounter = ({ count }: { count: number }) => {
  const countRef = useRef<HTMLHeadingElement>(null);

  useEffect(() => {
    const counterElement = countRef.current;
    if (counterElement) {
      let countValue = 0;
      const increment = Math.ceil(count / 100); // Increment value for smooth animation

      const updateCount = () => {
        countValue += increment;
        if (countValue >= count) {
          countValue = count;
          clearInterval(counterInterval);
        }
        counterElement.innerText = countValue.toString();
      };

      const counterInterval = setInterval(updateCount, 30); // Adjust the interval for smoother animation

      return () => clearInterval(counterInterval); // Cleanup interval on unmount
    }
  }, [count]);

  return <h2 ref={countRef} className="text-4xl font-bold text-gray-900 mb-3"></h2>;
};