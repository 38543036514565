import { Dispatch, SetStateAction, useState } from "react";

interface GalleryProps {
  current?: number;
  images: string[];
  setOpenModal: Dispatch<SetStateAction<boolean>>;
}

const Gallery = ({ images = [], current = 0, setOpenModal }: GalleryProps) => {
  const [active, setActive] = useState<number>(current);

  const next = () => {
    if (active + 1 > images.length - 1) {
      setActive(0);
    } else {
      setActive((prevState) => prevState + 1);
    }
  };

  const prev = () => {
    if (active - 1 < 0) {
      setActive(images.length - 1);
    } else {
      setActive((prevState) => prevState - 1);
    }
  };

  return (
    <div className="fixed inset-0 bg-black/75 h-screen">
      <div className="relative max-w-5xl mx-auto p-5">
        <button
          onClick={() => setOpenModal(false)}
          className="text-white bg-wine w-10 h-10 rounded-full absolute right-0 top-0 z-10"
        >
          {"X"}
        </button>
        <div className="relative rounded-xl h-[calc(100vh_-_150px)] w-full overflow-hidden bg-black/50">
          <img
            src={images[active]}
            alt=""
            className="h-full w-full object-cover"
          />
          <div className="absolute w-full top-1/2 text-white flex justify-between px-2">
            <button
              onClick={prev}
              className="text-white bg-wine w-10 h-10 rounded-full"
            >
              {"<<"}
            </button>
            <button
              onClick={next}
              className="text-white bg-wine w-10 h-10 rounded-full"
            >
              {">>"}
            </button>
          </div>
        </div>

        <div className="overflow-x-auto">
          <div className="flex justify-center gap-5 pb-4">
            {images.map((img, i) => {
              return (
                <div
                  key={i}
                  className="shrink-0 w-32 flex justify-center rounded-xl cursor-pointer mt-5"
                >
                  <img
                    className={`h-20 w-full rounded-lg object-cover ${active === i ? "ring ring-white ring-offset-4 ring-offset-black/75" : ""}`}
                    src={img}
                    alt=""
                    onClick={() => setActive(i)}
                  />
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Gallery;
