import { Link } from "react-router-dom";
import { useAppData } from "../hooks/useAppData";

const About = () => {
  const appData = useAppData();

  return (
    <div className="pt-16 md:pt-0">
      <div className="relative h-32 md:h-56 bg-black/70 flex justify-center items-center">
        <img
          src="/images/funfact-bg.jpg"
          alt=""
          className="absolute w-full h-full object-cover mix-blend-overlay"
        />
        <h3 className="text-white text-3xl md:text-[42px] font-semibold">
          About Us
        </h3>
      </div>

      <section className="p-5 md:p-16 lg:p-28 grid grid-cols-1 lg:grid-cols-2 gap-10">
        <div className="md:hidden">
          <img className="rounded-lg" alt=""
            src={
              appData?.about_us_image
                ? appData?.about_us_image
                : "/images/about-3.jpg"
            }
          />
        </div>
        <div className="hidden md:block">
          <img
            src={
              appData?.about_us_video
                ? appData?.about_us_video
                : "/images/about-3.jpg"
            }
            alt=""
            className="w-full rounded-lg"
          />
        </div>
        <div className="flex flex-col justify-center items-start">
          <h2 className="font-semibold text-2xl md:text-[36px] mb-5">
            {appData?.about_us_title}
          </h2>

          <p>
            {appData?.about_us_description
              ? appData?.about_us_description
              : "At Lemarini, customer satisfaction and timely execution of projects is our hallmark. For quality, reliable and dependable services contact us."}
          </p>
          <Link
            to="/services"
            className="bg-orange-600 text-white py-4 px-14 text-xs mt-10"
          >
            OUR SERVICES
          </Link>
        </div>
      </section>
    </div>
  );
};

export default About;
