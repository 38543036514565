import { useEffect } from "react";
import Router from "./components/routes";
import { useGetQuery } from "./hooks/useGetQuery";
import { useAppStore } from "./store/appStore";

function App() {
  const { data } = useGetQuery({ url: "/website" });
  const setData = useAppStore((store) => store.setData);

  console.log({ data });

  useEffect(() => {
    document.title = "LeMarini Construction";
    if (data?.message) {
      let link: HTMLLinkElement | null =
        document.querySelector("link[rel~='icon']");
      if (!link) {
        link = document.createElement("link");
        link.rel = "icon";
        document.head.appendChild(link);
      }
      link.href = data.message.favicon;
      setData(data.message);
    }
  }, [data?.message, setData]);

  return <Router />;
}

export default App;
