import { BrowserRouter, Route, Routes } from "react-router-dom";
import About from "../../pages/About";
import HomePage from "../../pages/HomePage";
import Services from "../../pages/services/Services";
import Footer from "../Footer";
import Header from "../Header";
import Contact from "../../pages/Contact";
import ServiceDetails from "../../pages/services/ServiceDetails";
import Projects from "../../pages/projects/Projects";
import ProjectDetails from "../../pages/projects/ProjectDetails";

const Router = () => {
  return (
    <BrowserRouter>
      <Header />
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/about" element={<About />} />
        <Route path="/contact-us" element={<Contact />} />
        <Route path="/services" element={<Services />} />
        <Route path="/services/:serviceId" element={<ServiceDetails />} />
        <Route path="/projects" element={<Projects />} />
        <Route path="/projects/:slug" element={<ProjectDetails />} />
      </Routes>
      <Footer />
    </BrowserRouter>
  );
};

export default Router;
