import { formatPhoneNumberIntl } from "react-phone-number-input";

import { useAppData } from "../hooks/useAppData";

function Footer() {
  const appData = useAppData();

  console.log({ appData });

  return (
    <div className="bg-gray-950 text-white p-8 md:p-16 lg:p-28">
      {/*====================  footer area ====================*/}
      <div className="grid grid-cols-12 gap-5">
        <div className="col-span-12 lg:col-span-3">
          {/* footer intro wrapper */}
          <div>
            <div className="w-[10rem] h-auto">
              <a href={`${process.env.PUBLIC_URL}/home-one`}>
                <img src={appData?.logo} className="img-fluid rounded-lg" alt="" />
              </a>
            </div>
            <div className="mt-5 text-sm leading-relaxed font-light w-11/12">
              {appData?.footer_section_description}
            </div>
          </div>
        </div>
        <div className="col-span-12 md:col-span-4 lg:col-span-3 w-full">
          {/* footer widget */}
          <div className="footer-widget">
            <h4 className="font-bold mb-10">USEFUL LINKS</h4>
            <ul className="font-light text-sm space-y-2">
              <li>
                <a href={`${process.env.PUBLIC_URL}/`}>Home</a>
              </li>
              <li>
                <a href={`${process.env.PUBLIC_URL}/about`}>About Us</a>
              </li>
              <li>
                <a href={`${process.env.PUBLIC_URL}/projects`}>Projects</a>
              </li>
              {/* <li>
                <a href={`${process.env.PUBLIC_URL}/`}>Careers</a>
              </li> */}
              {/* <li>
                <a href={`${process.env.PUBLIC_URL}/`}>Contact Us</a>
              </li> */}
            </ul>
          </div>
          <div className="rounded-lg mt-5  footer-container">
            <a href={appData?.android_app_link}>
              <img src="/images/play-store.png" alt="" className="w-32" />
            </a>
          </div>
        </div>
        <div className="col-span-12 md:col-span-4 lg:col-span-3">
          {/* footer widget */}
          <div className="footer-widget">
            <h4 className="font-bold mb-10">USEFUL LINKS</h4>
            <ul className="font-light text-sm space-y-2">
              <li>
                <a href={`${process.env.PUBLIC_URL}/`}>Our Services</a>
              </li>
              <li>
                <a href={`${process.env.PUBLIC_URL}/contact-us`}>Contact Us</a>
              </li>
              <li>
                <a href={`${process.env.PUBLIC_URL}/services`}>Services</a>
              </li>
              {/* <li>
                <a href={`${process.env.PUBLIC_URL}/`}>Disclaimer</a>
              </li> */}
              {/* <li>
                <a href={`${process.env.PUBLIC_URL}`}>Privacy Policy</a>
              </li> */}
            </ul>
          </div>
          <div className="rounded- mt-5  footer-container">
            <a href={appData?.ios_app_link}>
              <img src="/images/app-store.png" alt="" className="w-32" />
            </a>
          </div>
        </div>
        <div className="col-span-12 md:col-span-4 lg:col-span-3">
          {/* footer widget */}
          <div className="mb-0 space-y-2">
            <h4 className="font-bold mb-10">CONTACT US</h4>
            <div className="font-light text-sm space-y-5">
              <p className="address">{appData?.company_address}</p>
              <ul className="font-light">
                <li>{formatPhoneNumberIntl(appData?.contact_phone_1 ?? "")}</li>
                <li>{formatPhoneNumberIntl(appData?.contact_phone_2 ?? "")}</li>
                <li>{appData?.contact_email_1}</li>
                <li>{appData?.contact_email_2}</li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      {/*====================  End of footer area  ====================*/}
      {/*====================  scroll top ====================*/}
      {/* {scrollVisible && (
        <button className="scroll-top" id="scroll-top" onClick={scrollToTop}>
          <i className="ion-android-arrow-up" />
        </button>
      )} */}
      {/*====================  End of scroll top  ====================*/}
    </div>
  );
}

export default Footer;
