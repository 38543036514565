import { Link } from "react-router-dom";
import { Project } from "../../custom-types";
import { slugify } from "../../utils/slugify";

interface ProjectCardProps {
  project: Project;
}

const ProjectCard = ({ project }: ProjectCardProps) => {
  return (
    <div className="h-96 w-80 overflow-hidden justify-center rounded-md shadow-md border">
      <img src={project.thumbnail_image} className="h-48 w-full object-cover" />
      <div className="h-48 p-6 flex flex-col justify-between">
        <h3 className="font-semibold text-xl h-8 text-gray-700 line-clamp-1">
          {project.title}
        </h3>
        <p className="line-clamp-3 h-[70px] text-gray-500">
          {project.description}
        </p>
        <Link
          to={`/projects/${slugify(project.title)}`}
          className="text-wine block h-8"
        >
          See more...
        </Link>
      </div>
    </div>
  );
};

export default ProjectCard;
