import { useState } from "react";
import { IoMdHome } from "react-icons/io";
import { MdOutlinePhoneAndroid } from "react-icons/md";
import { formatPhoneNumberIntl } from "react-phone-number-input";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { useAppData } from "../hooks/useAppData";
import { HiOutlineMenuAlt2, HiOutlineDeviceMobile } from "react-icons/hi";
import { AiOutlineMail } from "react-icons/ai";

const Header = () => {
  const appData = useAppData();
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();

  const handleClick = (link: string) => {
    navigate(link);
    setIsOpen(false);
  };

  return (
    <div>
      {/* Desktop Navbar */}
      <div className="hidden md:block">
        {/* Quote Section */}
        <div className="bg-dark-gray h-12 px-10 lg:px-32 flex justify-end items-center">
          <Link
            to="/contact-us"
            className="bg-orange-600 h-full text-white w-40 inline-flex items-center text-sm justify-center"
          >
            GET A QUOTE
          </Link>
        </div>

        {/* Address Section */}
        <div className="flex items-center md:px-10 mx-auto lg:px-28 py-2">
          <div className="grid grid-cols-12 gap-10 items-center w-full">
            <Link to="/" className="shrink-0 col-span-3 flex items-center">
              <img
                src={
                  appData?.logo ? appData?.logo : "/images/lemarini_logo.jpg"
                }
                alt=""
                className="h-[86px] w-20"
              />
            </Link>
            <div className="col-span-4 lg:col-span-4 flex gap-5">
              <div className="shrink-0 w-[50px] h-[50px] rounded-full border border-wine flex items-center justify-center">
                <MdOutlinePhoneAndroid size={25} className="text-wine" />
              </div>
              <div>
                <h6 className="font-semibold text-lg">Phone</h6>
                <p className="whitespace-nowrap text-gray-600">
                  {formatPhoneNumberIntl(appData?.contact_phone_1 ?? "")}
                </p>
              </div>
            </div>
            <div className="col-span-5 flex gap-5 items-center">
              <div className=" shrink-0 w-[50px] h-[50px] rounded-full border border-wine flex items-center justify-center">
                <IoMdHome size={25} className="text-wine" />
              </div>
              <div>
                <h6 className="font-semibold text-lg">Address</h6>
                <p className="text-gray-600 text-sm">
                  {appData?.company_address}
                </p>
              </div>
            </div>
          </div>
        </div>

        {/* Navbar Section */}
        <div className="h-16 bg-orange-600 grid grid-cols-4  md:grid-cols-6 lg:grid-cols-12 items-center uppercase text-white justify-center">
          <NavLink
            to="/"
            className="md:col-start-2 lg:col-start-5 h-full px-5 leading-[64px]"
          >
            Home
          </NavLink>
          <NavLink
            to="/about"
            className="whitespace-nowrap h-full leading-[64px]"
          >
            About
          </NavLink>
          <NavLink
            to="/projects"
            className="whitespace-nowrap h-full leading-[64px]"
          >
            Projects
          </NavLink>

          <NavLink
            to="/services"
            className="whitespace-nowrap h-full leading-[64px]"
          >
            Services
          </NavLink>
          <NavLink
            to="/contact-us"
            className="whitespace-nowrap h-full leading-[64px]"
          >
            Contact us
          </NavLink>
        </div>
      </div>

      {/* Mobile Navbar */}
      <div className="relative md:hidden">
        <div className="w-full flex items-center justify-between h-16 z-50 shadow-lg px-5 fixed top-0 bg-white">
          <Link to="/" className="shrink-0 col-span-3 flex items-center">
            <img
              src={appData?.logo ? appData?.logo : "/images/lemarini_logo.jpg"}
              alt=""
              className="h-14 w-14 object-cover"
            />
          </Link>

          <button
            onClick={() => setIsOpen(!isOpen)}
            className="flex flex-col justify-center items-center"
          >
            <HiOutlineMenuAlt2 size={30} />
          </button>
        </div>

        <div
          className={`flex flex-col justify-between bg-white fixed right-0 h-[calc(100vh_-_64px)] w-9/12 z-50 p-5 top-16 transform ${
            isOpen ? "translate-x-0" : "translate-x-full"
          } duration-500`}
        >
          <ul className="space-y-3">
            <li onClick={() => handleClick("/")} className="py-2">
              HOME
            </li>
            <li onClick={() => handleClick("/about")} className="py-2">
              ABOUT
            </li>
            <li onClick={() => handleClick("/services")} className="py-2">
              SERVICE
            </li>
            <li onClick={() => handleClick("/contact-us")} className="py-2">
              CONTACT
            </li>
          </ul>

          <div className="space-y-2">
            <div className="flex items-center gap-2">
              <HiOutlineDeviceMobile
                size={appData?.contact_email_2 ? 30 : 25}
              />
              <div className="text-xs">
                <span className="block">
                  {formatPhoneNumberIntl(appData?.contact_phone_1 ?? "")}
                </span>
                <span className="block">
                  {formatPhoneNumberIntl(appData?.contact_phone_2 ?? "")}
                </span>
              </div>
            </div>
            <div className="flex items-center gap-2">
              <AiOutlineMail size={25} />
              <div className="text-xs">
                <span className="block">{appData?.contact_email_1}</span>
                <span className="block">{appData?.contact_email_2}</span>
              </div>
            </div>
            <div></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
