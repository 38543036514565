import { useAppData } from "../../hooks/useAppData";
import Layout from "../../components/Layout";
import ProjectCard from "./ProjectCard";

const Projects = () => {
  const appData = useAppData();

  return (
    <Layout label="Project" backgroundImage="/images/project-details.jpg">
      <div className="max-w-6xl mx-auto flex justify-center flex-wrap gap-8 px-5">
        {appData?.projects.map((project, idx) => (
          <ProjectCard key={idx} project={project} />
        ))}
      </div>
    </Layout>
  );
};

export default Projects;
