import { useQuery } from "@tanstack/react-query";
import axios from "axios";

const baseURL = process.env.REACT_APP_BASE_URL;
const appCode = process.env.REACT_APP_APP_CODE;

type UseGetQueryProps = {
  queryKey?: string[];
  url?: string;
  enable?: boolean;
};

export const useGetQuery = ({
  queryKey = ["website-data"],
  url = "",
  enable = true,
}: UseGetQueryProps) => {
  return useQuery({
    queryKey,
    queryFn: async () => {
      const res = await axios.get(`${baseURL}${url}`, {
        headers: {
          appcode: appCode,
        },
      });
      return res.data;
    },
    enabled: enable,
  });
};
